@import 'src/styles.scss';

$font-size: 16px;
$font-color: #005825;
$font-weight: inherit;
$line-height: inherit;
$letter-spacing: inherit;

@font-face{
    font-family: Lato Bold;
    src: url('/assets/templates/template_014/fonts/Lato-Bold.ttf');
}
@font-face{
    font-family: Lato Italic;
    src: url('/assets/templates/template_014/fonts/Lato-Italic.ttf')
}
@font-face{
    font-family: TriniteNo Italic;
    src : url('/assets/templates/template_014/fonts/TriniteNo4-Italic.otf');
}
@font-face{
    font-family: Lato Regular;
    src: url('/assets/templates/template_014/fonts/Lato-Regular.ttf');
}
@font-face {
    font-family: Lato Black;
    src: url('/assets/templates/template_014/fonts/Lato-Black.ttf');
}
@font-face {
    font-family: Lato Light;
    src: url('/assets/templates/template_014/fonts/Lato-Light.ttf');
}
@font-face{
    font-family: Myriad-Pro Bold;
    src: url('/assets/templates/template_014/fonts/MyriadPro-Bold.otf');
}

@font-face {
    font-family: ZapfinoForteLTPro;
    src: url('/assets/templates/template_014/fonts/ZapfinoForteLTPro.otf');
}
*{
    position: relative;
}

#suggestion-container {
    @include container(100%, 100%);
    border-bottom: medium none !important;
}
input:focus {
    outline: medium none !important;
    box-shadow: 0 0 8px hsl(0, 0%, 88%);   
}
#suggestion-container input,span#lnkGo {
    padding-left: 10px;
    margin: 0;
    height: 100%;
    @include font-style(Lato Regular,18px,#333, normal);
}
#suggestion-container input:focus{
    color: rgb(54, 78, 79) !important;
}
#suggestion-container input::-webkit-input-placeholder{
    color: white;
}
#suggestion-container input::-moz-placeholder{
    color: white;
}
#suggestion-container input:-moz-placeholder{
    color: white;
}
.goPnl {
    width: 16.66666667%;
    display: inline-block;
    float: left;
    height: 100%;
}
#lnkGo {
    text-align: right;
    font-family: fontawesome !important;
}
// #suggestion-container input:focus {
//     color: #fff !important;
// }
button:focus, a:focus {
    outline: medium none !important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
perfect-scrollbar{
    margin-bottom: 20px !important;
    max-height: 240px !important;
    overflow: auto !important;
    height: auto !important;
}
// #options-overlay {
//     width: 14% !important;
//     max-height: 220px !important;
// }
#options-overlay{
    top: 29px !important;
    left: -10px !important;
    max-height: 220px !important;
    box-shadow: none !important;
    width: 106% !important;    
}
#options-overlay a {
    font-size: 12px;
    margin: 0;
    color: #7A7A7A;
    display: inline-block;
    width: 100%;
}
.waves-effect.option div {
    padding: 7px;
}
.loginPopUp .login-component{
    height: 420px;
    width: 770px;
    max-width: 770px !important;
    margin-bottom: 0 !important;
}
.loginPopUp .heading-lbl{
    visibility: visible !important;
}
body{
    font-family: Lato Regular;
    min-height: 100%;
    height: auto !important;
}
.mat-dialog-container{
    border: medium none !important;
    box-shadow: none !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    background: transparent !important;
}
.hide{
    display: none !important;
    opacity: 0;
}
.rating-container{
    padding: 0px !important;
}
ls-suggestion{
    width: 83.3333% !important;
    float: left;
}
ls-suggestion i{
    display: none !important;
}
.col-xs{
    display: inline-block;
    height: 100%;
    width: 100%;
}
input{
    padding: 0 10px;
}
a {
    color: #428bca;
    text-decoration: none;
    outline: medium none;
    cursor: pointer;
}

input.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}

textarea.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}

select.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}

input.ng-invalid.ng-touched{
    border: 1px solid #ff0000 !important;
}

textarea.ng-invalid.ng-touched {
    border: 1px solid #ff0000 !important;
}

select.ng-invalid.ng-touched{
    border: 1px solid #ff0000 !important;
}

.master{
    height: auto !important;
    position: relative;
    background-color: #fff;
}
.file-snip p{
    font-family: Myriad-Pro Regular;
    color: #005825;
    font-size: 14px;
}
.ps__rail-y {
    background-color: #aaa !important;
    width: 3px !important;
}

.ps__thumb-y {
    width: 4px !important;
    right: 0px !important;
    background-color: rgb(110, 171, 30) !important;
   
}
.static-page *{
    max-width: 100%;
    // width: auto !important;
    height: auto !important;
}

.button{
    clear: left;
    background: #ef4836 none repeat scroll 0 0;
    border-radius: 4px;
    float: left;
    font-family: Lato Regular;
    font-size: 18px;
    height: 40px;
    width: 150px;
    border: medium none;
    color: #fff;
    cursor: pointer;
}
.button:hover{
    background: #fff !important;
    border: 1px solid #ef4836;
    border-radius: 4px;
    color: #ef4836;
    font-family: Lato Regular;
}
button:disabled{
    opacity: 0.5 !important;
    // pointer-events: none;
    cursor: not-allowed;
}
.textFields{
    height: 100%;
    min-height: 30px;
    width: 100%;
    background-color: #ebf3f6;
    padding: 0 10px;
    color: #7A7A7A;
    font-size: 16px;
    font-family: Lato Regular;
}
textarea:focus, input:focus, select:focus{
    outline: none;
    box-shadow: 0 0 8px hsl(0, 0%, 88%);
}
:host ::ng-deep .mat-radio-label-content {
    font-size: 14px;
    font-family: Lato Regular;
    color: #333;
}
.heading{
    font-family: Lato Regular;
    font-size: 30px;
    color: #364e4f;
    text-transform: capitalize;
}
option{
    background-color: white;
}
.errorLabel {
    font-size: 12px;
    color: red;
    visibility: visible;
    display: inline-block;
    width: 100%;
    line-height: 20px;
}
.showBg{
    background-image: url('/assets/templates/template_014/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
}
.mat-tab-nav-bar{
    border-bottom: none !important;
}
@media screen and (min-width:1024px){
    .panelClass {
        position: absolute !important;
        right: 22% !important;
        top: 130px;
    }
}
@media screen and (max-width: 1024px){
    .search-panel app-search > div{
        background-color: transparent;
    }
    app-search{
        width: 100%;
    }
    #options-overlay{
        width: 80% !important;
    }
    .static-page * {
        max-width: 100%;
        width: auto;
        line-height: 1.2;
        font-size: 1em !important;
    }
    .static-page table{
        height: 100% !important;
        width: 100% !important;
    }
}
@media screen and (max-width:767px){
    #options-overlay {
        width: 100% !important;
        top: 35px !important;
    }
    #options-overlay a{
        font-weight: bold;
    }
    span#lnkGo{
        font-size: 18px;
    }
    .goPnl{
        text-align: center;
    }
    .static-page * {
        max-width: 100%;
        width: auto;
        line-height: 1.2;
        font-size: 1em !important;        
    }
    .static-page table{
        height: 100% !important;
        width: 100% !important;
    }
    .static-page img {
        width: 100% !important;
        height: auto;
        vertical-align: middle;
    }
    //  .showBg{
    //     background-image: url('/assets/templates/template_014/images/background-image-mobile.jpg')
    // }
    #suggestion-container input,span#lnkGo, #suggestion-container input::-webkit-input-placeholder, #suggestion-container input:focus{
        color: #364e4f !important;
    }
    .cdk-global-overlay-wrapper{
        background-color: rgba(0,0,0,0.7) !important;
    }
    app-order-tracking {
        padding: 0 20px;
        display: inline-block;
        width: 100%;
    }
    .mce-panel {
        width: 100% !important;
    }
    ._2p3a{
        min-width: 100% !important;
    }
    .static-page p{
        line-height: 1.5;
    }
    .typeSpecFilterPanel perfect-scrollbar{
        margin-bottom: 0 !important
    }
    .textFields{
        font-size: 14px;
    }
    .button:hover{
        background: #ef4836 none repeat scroll 0 0 !important;
        border: 1px solid #ef4836 !important;
        color: #fff;
    }
    .heading{
        font-size: 20px;
    }
}
@media screen and (min-width :1024px) and (max-width:1280px){
    .panelClass{
        right: 0 !important;
    }
}
@media screen and (min-width: 768px){
    .app-header-banner{
        display: inline-block;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    .app-header-default{
        display: inline-block;
        width: 100%;
        position: inherit;
    }
}